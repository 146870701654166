/** @jsx jsx */
import { jsx, Themed, Flex } from "theme-ui"
import { Link } from "gatsby"
import { BlogPostIndexNode } from "../pages/blog"

function capitaliseFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const BlogPostCard = ({ node }: { node: BlogPostIndexNode }) => {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <article key={node.fields.slug}>
      <header>
        <Themed.h3
          sx={{
            marginBottom: "0em",
            color: `secondary`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={node.fields.slug}
          >
            {title}
          </Link>
        </Themed.h3>
        <Themed.div
          sx={{
            marginTop: "0.5em",
          }}
        >
          <Themed.h4
            sx={{
              marginTop: "0.5em",
              fontFamily: `body`,
              color: `text`,
            }}
          >
            <em>
              {" "}
              {"Category: " +
                capitaliseFirstLetter(node.frontmatter.contentStage)}{" "}
            </em>
          </Themed.h4>
          <Flex>
            <Themed.h4
              sx={{
                marginRight: "1em",
                marginTop: "0.5em",
                fontFamily: `body`,
                color: `secondary`,
              }}
            >
              {node.frontmatter.datePublished}
            </Themed.h4>
            <Themed.h4
              sx={{
                marginLeft: "1em",
                marginTop: "0.5em",
                fontFamily: `body`,
                color: `text`,
              }}
            >
              {node.timeToRead + " min read"}
            </Themed.h4>
          </Flex>
        </Themed.div>
      </header>
      <section>
        <Themed.p> {node.frontmatter.description}</Themed.p>
      </section>
    </article>
  )
}

export default BlogPostCard
