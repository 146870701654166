import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const EmailSubscriptionForm = makeShortcode("EmailSubscriptionForm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Welcome to my blog! This blog is a `}<em parentName="p">{`digital garden`}</em>{` of ideas and tutorials.`}</p>
    <p>{`These ideas can really be separated into
buckets, in increasing order of “completeness”. I guess one way of viewing this blog is as a learn-in-public incubator of ideas.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Snippets`}</strong>{`: one-off ideas or tweets, could be later combined to form posts.`}</li>
      <li parentName="ul"><strong parentName="li">{`Notes`}</strong>{`: a combination of ideas, taking shape but still rough around the edges.`}</li>
      <li parentName="ul"><strong parentName="li">{`Posts`}</strong>{`: “complete” tutorials / opinion pieces, may revisit them from time to time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Featured`}</strong>{`: these are the posts I’m really happy with.`}</li>
    </ul>
    <p>{`I’ve also written two series of posts: on `}<a parentName="p" {...{
        "href": "/demystifying-deep-learning/maths-behind-deep-learning/"
      }}>{`understanding the maths behind deep learning`}</a>{` and `}<a parentName="p" {...{
        "href": "/create-your-own-programming-language/intro-to-compiler/"
      }}>{`writing your own programming language`}</a></p>
    <EmailSubscriptionForm heading="My best ideas, curated for you in a newsletter." description="Enjoy early access to posts, before I make them public!" mdxType="EmailSubscriptionForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      