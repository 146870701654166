/** @jsx jsx */
import { ChangeEvent, useState } from "react"
import { jsx, Themed, Button, Input, Label } from "theme-ui"
import React from "react"
import * as JsSearch from "js-search"
import { BlogPostIndexNode } from "../pages/blog"
import BlogPostCard from "./blog-post-card"

enum TabType {
  All = "All",
  Snippets = "Snippets",
  Notes = "Notes",
  Posts = "Posts",
  Featured = "Featured",
}

const Tab = ({
  value,
  activeTab,
  setActiveTab,
}: {
  value: TabType
  activeTab: TabType
  setActiveTab: (newActiveTab: TabType) => void
}) => {
  let isActive = value === activeTab
  return (
    <Button
      sx={{
        backgroundColor: `background`,
        borderRadius: 0,
        marginRight: "3em",
        paddingX: 0,
      }}
      onClick={() => setActiveTab(value)}
    >
      <Themed.h2
        sx={{
          marginY: 0,
          opacity: isActive ? "100%" : "60%",
          textAlign: "left",
        }}
      >
        {value}
      </Themed.h2>
    </Button>
  )
}

const ActiveSection = ({
  posts,
  activeTab,
}: {
  posts: BlogPostIndexNode[]
  activeTab: TabType
}) => {
  let publishedPosts = posts.filter(
    node =>
      process.env.NODE_ENV === "development" ||
      node.frontmatter.audience === "all"
  )

  let displayedPosts = []

  switch (activeTab) {
    case TabType.Featured:
      displayedPosts = publishedPosts.filter(
        node => node.frontmatter.isFeatured
      )
      break
    case TabType.Snippets:
      displayedPosts = publishedPosts.filter(
        node => node.frontmatter.contentStage === "snippet"
      )
      break
    case TabType.Notes:
      displayedPosts = publishedPosts.filter(
        node => node.frontmatter.contentStage === "note"
      )
      break
    case TabType.Posts:
      displayedPosts = publishedPosts.filter(
        node => node.frontmatter.contentStage === "post"
      )
      break
    case TabType.All:
      displayedPosts = publishedPosts
  }
  return <SearchSection publishedPosts={displayedPosts} key={activeTab} />
}

const BlogPostSelector = ({ posts }: { posts: BlogPostIndexNode[] }) => {
  const [activeTab, setActiveTab] = useState(TabType.All)

  return (
    <Themed.div sx={{ paddingX: "3em", marginTop: "1em" }}>
      <Themed.div sx={{ marginTop: "4em", marginBottom: "-2em" }}>
        {Object.values(TabType).map(tabVal => (
          <Tab
            value={tabVal}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            key={tabVal}
          />
        ))}
      </Themed.div>
      <ActiveSection activeTab={activeTab} posts={posts} />
    </Themed.div>
  )
}

const SearchSection = ({
  publishedPosts,
}: {
  publishedPosts: BlogPostIndexNode[]
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [queryResult, setQueryResult] = useState(publishedPosts)
  const dataToSearch = new JsSearch.Search("id")
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
  dataToSearch.addDocuments(publishedPosts) // adds the data to be searched
  dataToSearch.addIndex(["frontmatter", "title"])
  dataToSearch.addIndex(["frontmatter", "tags"])
  dataToSearch.addIndex(["frontmatter", "description"])

  const performQuery = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery)
    if (newSearchQuery === "") {
      setQueryResult(publishedPosts)
    } else {
      const queryResult = dataToSearch.search(
        newSearchQuery
      ) as BlogPostIndexNode[]
      setQueryResult(queryResult)
    }
  }

  const onSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    performQuery(e.target.value)
  }
  return (
    <Themed.div sx={{ marginTop: "4em" }}>
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <Label htmlFor="Search">
          <Themed.p>Enter your search here:</Themed.p>
        </Label>
        <Input
          id="Search"
          value={searchQuery}
          onChange={onSearchQueryChange}
          placeholder="e.g. compilers, deep learning, facebook, gatsby, git"
          sx={{ maxWidth: "450px" }}
        />
      </form>
      {queryResult.length > 0 ? (
        queryResult.map(node => <BlogPostCard key={node.id} node={node} />)
      ) : (
        <Themed.div sx={{ paddingY: "5em", paddingX: "1em", fontSize: 4 }}>
          I don't have any content of this type!
        </Themed.div>
      )}
    </Themed.div>
  )
}

export default BlogPostSelector
