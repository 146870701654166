/** @jsx jsx */
import { jsx, Themed, Flex } from "theme-ui"
import { PageProps, graphql } from "gatsby"
//@ts-ignore
import { Follow } from "react-twitter-widgets"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostSelector from "../components/blog-post-selector"
import EmailSubscriptionForm from "../components/email-subscription-form"
import BlogDescription from "../components/mdx/blog/blog-description.mdx"

import { MDXProvider } from "@mdx-js/react"

export type BlogPostIndexNode = {
  id: number
  timeToRead: number
  frontmatter: {
    title: string
    datePublished: string
    description: string
    image: {
      childImageSharp: {
        gatsbyImageData: any
      }
    }
    audience: "me" | "all" | "email"
    contentStage: "snippet" | "note" | "post"
    isFeatured: boolean
    tags?: string[]
  }
  fields: {
    slug: string
  }
}

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    edges: {
      node: BlogPostIndexNode
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const posts = data.allMdx.edges.map(({ node }) => node)
  const firstPostImage = posts[0].frontmatter.image
  return (
    <Layout page="/blog">
      <SEO
        title="Blog Homepage"
        path={location.pathname}
        pageType="blog"
        imageNode={firstPostImage}
      />
      <Themed.div
        sx={{
          paddingX: "3em",
          bg: `navBarBackground`,
          paddingBottom: "3em",
          borderRadius: "0px 0px 25px 25px",
        }}
      >
        <Themed.h1
          sx={{
            margin: 0,
            paddingY: 0,
            fontWeight: `light`,
            color: `navBarPrimary`,
          }}
        >
          Learn. Make. Blog. Repeat
        </Themed.h1>
        <Themed.h2
          sx={{
            margin: 0,
            paddingTop: "0.5em",
            paddingBottom: "0.75em",
            color: `navBarSecondary`,
          }}
        >
          My thoughts on computer science, software engineering and beyond.
        </Themed.h2>
        <Follow
          username="mukulrathi_"
          options={{ size: "large", showCount: "false" }}
        />
      </Themed.div>
      <Themed.div
        sx={{
          marginRight: "auto",
          maxWidth: "900px",
          marginLeft: ["0em", "5em"],
        }}
      >
        <Themed.div
          sx={{ marginLeft: ["1.5em", "3em"], marginRight: ["1.5em"] }}
        >
          <MDXProvider
            components={{
              EmailSubscriptionForm,
            }}
          >
            <BlogDescription />
          </MDXProvider>
        </Themed.div>
        <BlogPostSelector posts={posts} />
      </Themed.div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___datePublished], order: DESC }) {
      edges {
        node {
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            datePublished(formatString: "DD MMMM, YYYY")
            title
            audience
            contentStage
            isFeatured
            tags
            description
            image {
              childImageSharp {
                gatsbyImageData(width: 630, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
